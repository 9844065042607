import * as React from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

const options = [
  'Take neutral values',
  'Take from last season',
  'Take from "wettopa"',
  'Take "earned" values',
];

export function ConfirmationDialogRaw(props) {
  const {onConfirm, onCancel, errorMessage, user, value: valueProp, open, ...other} = props;
  const [value, setValue] = React.useState(valueProp);
  const [isSubmitable, setIsSubmitable] = React.useState(false);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onConfirm(value);
  };

  const handleChange = (event) => {
    setValue(event.target.defaultValue);
  };

  function checkIfActivated(userId) {
    const strs = [
      "bWFyY3VzLWJiZ0B3ZWIuZGU=",
      "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
      "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
      "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
      "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
      "emFzdGVyNjBAb25saW5lLmRl",
      "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
      "bWhlaWRidWVjaGVsQG1haWwuZGU=",
      "aGVpbWFubi5oQGFvbC5jb20=",
      "c3RlZmZlbndlaWNrLjFAZ214LmRl",
      "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
      "eWFubmljZ295bkBnbWFpbC5jb20=",
      "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
      "a3Vuei5zdDNmYW5AZ21haWwuY29t",
      "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
      "bWlvczc4QGdteC5kZQ==",
      "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
      "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
      "dHJhaW5lcjhAZ214LmRl",
      "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
      "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
      "ZmVsdGk5MDBAd2ViLmRl",
      "amFubmlzc2lkZXJhc0B3ZWIuZGU=",
      "ZnJhbmNvdGFmdXJvQGdteC5kZQ==",
      "bWFyY3NraXBwZXJAd2ViLmRl",
      "cGFzY2FsYm9yeXNAZ21haWwuY29t",
      "MDdsZWh3YWxkMTJAd2ViLmRl",
      "YmVuYmFsazIuMEBnbWFpbC5jb20=",
      "YmVuYmFsazIuMEBnbWFpbC5jb20=",
      "bWFyaXVzLm1laXNidXJnQGdtYWlsLmNvbQ==",
      "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
      "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
      "anVhbm1hc3NhbmV0QGdteC5kZQ==",
      "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
      "cy5tb2VsbGVyNzlAZ21haWwuY29t",
      "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
      "bXIubW9saUB3ZWIuZGU=",
      "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
      "ZGVtb2huQG91dGxvb2suZGU="
    ];
    if (strs.includes(btoa(userId))) {
      return false;
    }
    return true;
  }

  return (
    <Dialog
      sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
      maxWidth="xs"
      TransitionProps={{onEntering: handleEntering}}
      open={open}
      {...other}
    >
      <DialogTitle textAlign="center">Init</DialogTitle>
      <DialogContent dividers>
        <Alert sx={{display: (errorMessage !== "") ? "flex" : "none"}} severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorMessage}
        </Alert>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {options.map((option) => {
            var title=option;
            var isDisabled = false;
            try {
              if (option === 'Take "earned" values') {
                isDisabled = true;
                title = title + " (Under construction)"
              }
              if (option === 'Take from "wettopa"') {
                isDisabled = checkIfActivated(user.user.id);
                title = title + " (in Silver package)"
              }
            } catch (e) {
              //console.log(e)
            }
            return <FormControlLabel
              value={option}
              key={option}
              disabled={isDisabled}
              control={<Radio/>}
              label={title}
            />
          })}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button disabled={!(typeof value === "string")} onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};

