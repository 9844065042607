import {Box, CircularProgress, Typography} from "@mui/material";
import {Fragment, useState} from "react";
import {HtmlTooltip} from "../styled-components/HtmlTooltip";
import {fetchFromApi} from "../helpers/fetchFromApi";

export function OddsTooltip({oddsId, label, children, user}) {
  const [highest, setHighest] = useState(false);
  const [average, setAverage] = useState(false);

  return (
    <div>
      <HtmlTooltip
        onOpen={async (e) => {
          const user = (await (await fetchFromApi("get-user")).json());
          const strs = [
            "bWFyY3VzLWJiZ0B3ZWIuZGU=",
            "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
            "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
            "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
            "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
            "emFzdGVyNjBAb25saW5lLmRl",
            "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
            "bWhlaWRidWVjaGVsQG1haWwuZGU=",
            "aGVpbWFubi5oQGFvbC5jb20=",
            "c3RlZmZlbndlaWNrLjFAZ214LmRl",
            "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
            "eWFubmljZ295bkBnbWFpbC5jb20=",
            "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
            "a3Vuei5zdDNmYW5AZ21haWwuY29t",
            "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
            "bWlvczc4QGdteC5kZQ==",
            "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
            "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
            "dHJhaW5lcjhAZ214LmRl",
            "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
            "cGF1bGVAcGF1bGUtd2V0dGVuLmRl",
            "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
            "ZmVsdGk5MDBAd2ViLmRl",
            "amFubmlzc2lkZXJhc0B3ZWIuZGU=",
            "ZnJhbmNvdGFmdXJvQGdteC5kZQ==",
            "bWFyY3NraXBwZXJAd2ViLmRl",
            "cGFzY2FsYm9yeXNAZ21haWwuY29t",
            "MDdsZWh3YWxkMTJAd2ViLmRl",
            "YmVuYmFsazIuMEBnbWFpbC5jb20=",
            "YmVuYmFsazIuMEBnbWFpbC5jb20=",
            "bWFyaXVzLm1laXNidXJnQGdtYWlsLmNvbQ==",
            "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
            "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
            "anVhbm1hc3NhbmV0QGdteC5kZQ==",
            "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
            "cy5tb2VsbGVyNzlAZ21haWwuY29t",
            "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
            "bXIubW9saUB3ZWIuZGU=",
            "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
            "ZGVtb2huQG91dGxvb2suZGU="
          ];
          if (typeof user !== "undefined" && strs.includes(btoa(user.id))) {
            var response = await (await fetchFromApi(`fetch-data/odds/fixture/${oddsId}`)).json();
            let data = response.data.find((e) => e.name === "3Way Result")?.bookmaker.data.map((e) => Number(e.odds.data.find((e) => e.label === label)?.value));
            setHighest(Math.max(...data).toFixed(3));
            setAverage((data.reduce((partialSum, a) => partialSum + a, 0) / data.length).toFixed(3));
          }
        }}
        title={
          <Fragment>
            <Box
              display={((highest || average) ? "none" : "inline-block")}
              style={{
                margin: "auto",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                height: "10px",
              }}
            >
              <CircularProgress/>
            </Box>
            <Typography display={((highest) ? "block" : "none")}>highest: {highest}</Typography>
            <Typography display={((average) ? "block" : "none")}>average: {average}</Typography>
          </Fragment>
        }
      >
        {children}
      </HtmlTooltip>
    </div>
  );
}
